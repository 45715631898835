import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// Disabled to make it easier to switch from ?react=1 to react=0
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from "./registerServiceWorker";

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
unregister();
