const fetchJson = (url, options = {}) => {
  options.credentials = "same-origin";
  const f = fetch(url, options).then(rsp => {
    if (rsp.ok) {
      return rsp.json();
    } else {
      throw rsp;
    }
  });
  return f;
};

export default fetchJson;
