import React, { Component } from "react";
import {
  Alert,
  Col,
  Grid,
  Row,
  FormControl,
  Button,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from "react-bootstrap";

import fetchJson from "./fetchJson.js";

/* From https://react-bootstrap.github.io/components.html#forms */
const FieldGroup = ({ id, label, help, onChangeValue, ...props }) => (
  <FormGroup controlId={id}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl onChange={e => onChangeValue(id, e.target.value)} {...props} />
    {help && <HelpBlock>{help}</HelpBlock>}
  </FormGroup>
);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      error: null,
    };
  }

  authenticate(e) {
    e.preventDefault();
    const data = new FormData();
    data.append("username", this.state.username);
    data.append("password", this.state.password);
    fetchJson("/api/signin", {
      body: data,
      method: "POST",
    })
      .then(rsp => this.props.onAuthenticateUser(rsp.user))
      .catch(() =>
        this.setState({
          error: "Incorrect username or password.",
          password: "",
        })
      );
  }

  useLegacyUI(e) {
    document.cookie = "react=false";
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12} sm={8} smOffset={2} md={4} mdOffset={4}>
            <form onSubmit={e => this.authenticate(e)}>
              {this.state.error && (
                <Alert bsStyle="danger">
                  <strong>{this.state.error}</strong>
                </Alert>
              )}
              <FieldGroup
                label="Username"
                val={this.state.username}
                onChange={e => this.setState({ username: e.target.value })}
              />
              <FieldGroup
                label="Password"
                type="password"
                val={this.state.password}
                onChange={e => this.setState({ password: e.target.value })}
              />
              <Button block type="submit">
                Sign In
              </Button>
            </form>

            <a onClick={e => this.useLegacyUI(e)} href="/">
              Legacy UI
            </a>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default Login;
