import React, { Component } from "react";
import { Col, Grid, Row, Table, FormGroup, FormControl, Button, Form } from "react-bootstrap";
import fetchJson from "./fetchJson";

const GOODREADS_BOOK_URL_PATTERN = /https:\/\/www.goodreads.com\/book\/show\/(\d+)/;

class Search extends Component {
  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);
    this.state = {
      query: query.get("q") || "",
      results: null,
      addURL: "",
      isValidURL: true,
    };
  }

  componentDidMount() {
    if (this.state.query !== null && this.state.query !== "") {
      this.doSearch();
    }
  }

  doSearch() {
    this.props.history.replace({ search: `?q=${this.state.query}` });
    fetchJson(`/api/search?q=${this.state.query}`).then(data => this.setState({ results: data }));
  }

  addViaUrl() {
    const match = this.state.addURL.match(GOODREADS_BOOK_URL_PATTERN);
    const goodreadsId = match[1];
    // TODO: Show error
    fetchJson(`/api/books?goodreads_id=${goodreadsId}`, { method: "POST" }).then(data =>
      this.props.history.push(`/books/${data.id}`)
    );
  }

  isValidURL(url) {
    return url.trim().length === 0 || GOODREADS_BOOK_URL_PATTERN.test(url);
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <Form
              inline
              onSubmit={e => {
                e.preventDefault();
                this.addViaUrl();
              }}
            >
              <FormGroup validationState={this.state.isValidURL ? null : "error"}>
                <FormControl
                  type="text"
                  placeholder="Goodreads URL"
                  value={this.state.addURL}
                  onChange={e =>
                    this.setState({
                      addURL: e.target.value,
                      isValidURL: this.isValidURL(e.target.value),
                    })
                  }
                />
              </FormGroup>{" "}
              <Button
                type="submit"
                disabled={!(this.state.addURL.length > 0 && this.state.isValidURL)}
              >
                Add
              </Button>
            </Form>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form
              inline
              onSubmit={e => {
                e.preventDefault();
                this.doSearch();
              }}
            >
              <FormGroup>
                <FormControl
                  type="text"
                  placeholder="Search"
                  value={this.state.query}
                  onChange={e => this.setState({ query: e.target.value })}
                />
              </FormGroup>{" "}
              <Button type="submit">Search</Button>
            </Form>
          </Col>
        </Row>

        <SearchResults
          items={this.state.results}
          onBookAdded={book => this.props.history.push(`/books/${book.id}`)}
        />
      </Grid>
    );
  }
}

const SearchResults = ({ items, onBookAdded }) => {
  if (items === null) {
    return null;
  } else {
    return (
      <Row>
        <Col md={12}>
          <Table striped condensed>
            <tbody>
              {items.map(i => (
                <SearchRow key={i.id} item={i} onBookAdded={onBookAdded} />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
};

class AddBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
    };
  }

  doAddBook() {
    this.setState({ fetching: true });
    fetchJson(`/api/books?goodreads_id=${this.props.id}`, { method: "POST" })
      .then(book => {
        this.props.onBookAdded(book);
      })
      .catch(err => {
        const isJson = err.headers.get("Content-Type") === "application/json";
        const p = isJson ? err.json() : Promise.resolve(err);
        p.then(err => {
          const errorMessage = err.error || JSON.stringify(err);
          alert(`Error adding book: ${errorMessage}`);
        });
      })
      .then(() => this.setState({ fetching: false }));
  }

  render() {
    return (
      <Button onClick={() => this.doAddBook()} disabled={this.state.fetching}>
        Add
      </Button>
    );
  }
}

const SearchRow = props => (
  <tr key={props.item.id}>
    <td>
      <img width={150} src={props.item.image_url} alt={`Book cover of ${props.item.title}`} />
    </td>
    <td>
      <a href={props.item.details_url}>{props.item.title}</a>
    </td>
    <td>{props.item.author}</td>
    <td>
      <AddBook id={props.item.id} onBookAdded={props.onBookAdded} />
    </td>
  </tr>
);

export default Search;
