import strftime from "strftime";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Grid, Row, Table } from "react-bootstrap";
import sortBy from "lodash.sortby";
import { Link } from "react-router-dom";
import fetchJson from "./fetchJson";
import FieldGroup from "./FieldGroup";

class BookList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      books: [],
      filter: "",
    };
  }

  componentDidMount() {
    fetchJson("/api/books").then(data => this.setState({ books: data }));
  }

  filteredBooks() {
    const filter = this.state.filter.toLowerCase();
    const matchesFilter = book => {
      return (
        book.title.toLowerCase().includes(filter) || book.author.toLowerCase().includes(filter)
      );
    };
    return filter === "" ? this.state.books : this.state.books.filter(matchesFilter);
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <FieldGroup
              id="filter"
              label="Search"
              value={this.state.filter}
              onChangeValue={(k, v) => this.setState({ filter: v })}
            />
            <BookTable books={this.filteredBooks()} />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const COLUMNS = [
  { id: "title", name: "Title", render: (v, book) => <Link to={`/books/${book.id}`}>{v}</Link> },
  { id: "author", name: "Author" },
  { id: "status", name: "Status" },
  { id: "updated_at", name: "Updated At", render: v => <FormattedDate date={v} /> },
  { id: "finished_at", name: "Finished At", render: v => <FormattedDate date={v} /> },
  { id: "isbn", name: "ISBN" },
];

const sortAsc = arr => arr;
const sortDesc = arr => arr.slice().reverse();

class BookTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "updated_at",
      order: "desc",
    };
  }

  setSortBy(column) {
    if (this.state.sortBy === column) {
      this.setState({ order: this.state.order === "asc" ? "desc" : "asc" });
    } else {
      this.setState({ sortBy: column });
    }
  }

  sortedBooks() {
    const sortOrder = this.state.order === "asc" ? sortAsc : sortDesc;
    return sortOrder(sortBy(this.props.books, b => b[this.state.sortBy]));
  }

  render() {
    return (
      <Table striped condensed>
        <BookHeader columns={COLUMNS} onHeaderClick={column => this.setSortBy(column)} />
        <tbody>
          {this.sortedBooks().map(b => (
            <BookRow key={b.id} columns={COLUMNS} book={b} />
          ))}
        </tbody>
      </Table>
    );
  }
}

BookTable.propTypes = {
  books: PropTypes.array.isRequired,
};

const BookHeader = props => (
  <thead>
    <tr>
      {props.columns.map(c => (
        <th key={c.id} style={{ cursor: "pointer" }} onClick={() => props.onHeaderClick(c.id)}>
          {c.name}
        </th>
      ))}
    </tr>
  </thead>
);

BookHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }))
    .isRequired,
  onHeaderClick: PropTypes.func.isRequired,
};

const BookRow = props => (
  <tr>
    {props.columns.map(c => (
      <td key={c.id}>{c.render ? c.render(props.book[c.id], props.book) : props.book[c.id]}</td>
    ))}
  </tr>
);

const FormattedDate = props => props.date === null ? <span></span> : <span>{strftime("%Y-%m-%d", new Date(props.date * 1000))}</span>;

export default BookList;
