import React, { Component } from "react";
import { Col, Grid, Row, Table, Button, Panel } from "react-bootstrap";
import fetchJson from "./fetchJson";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokens: [],
    };
  }

  componentDidMount() {
    fetchJson("/api/tokens").then(data => this.setState({ tokens: data }));
  }

  addToken(name) {
    const formData = new FormData();
    formData.append("name", name);
    fetchJson("/api/tokens", { method: "POST", body: formData }).then(data =>
      this.setState({ tokens: this.state.tokens.concat(data) })
    );
  }

  deleteToken(id) {
    fetchJson(`/api/tokens/${id}`, { method: "DELETE" }).then(data =>
      this.setState({ tokens: this.state.tokens.filter(t => t.id !== id) })
    );
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <h1>Settings</h1>
            <h2>API Tokens</h2>
            <NewTokens tokens={this.state.tokens.filter(k => k["token"])} />
            <APITokens
              tokens={this.state.tokens}
              onAddToken={name => this.addToken(name)}
              onDeleteToken={id => {
                if (window.confirm("Are you sure you want to delete this API token permanently?")) {
                  this.deleteToken(id);
                }
              }}
            />
          </Col>
        </Row>
      </Grid>
    );
  }
}

const NewTokens = ({ tokens }) => {
  if (tokens.length === 0) {
    return <div />;
  } else {
    return tokens.map(k => <NewToken {...k} />);
  }
};

const NewToken = ({ name, token }) => (
  <Panel bsStyle="success">
    <Panel.Heading>
      <Panel.Title componentClass="h3">New API Token</Panel.Title>
    </Panel.Heading>
    <Panel.Body>
      <p>
        You have created a new API token <strong>{name}</strong> with the secret:
      </p>
      <pre>{token}</pre>
      <p>
        This is the <em>only</em> time you will see this value so you should save it to use with
        your API calls.
      </p>
    </Panel.Body>
  </Panel>
);

class APITokens extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  render() {
    return (
      <div>
        <Table striped condensed>
          <thead>
            <tr>
              <th>Name</th>
              <th>Last Used</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.tokens.map(k => (
              <APITokenRow key={k.id} onDeleteToken={this.props.onDeleteToken} {...k} />
            ))}
          </tbody>
        </Table>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.props.onAddToken(this.state.name);
          }}
        >
          <input
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
            name="name"
          />
          <Button type="submit">Add</Button>
        </form>
      </div>
    );
  }
}

const ppTime = timestamp => {
  const now = Date.now();
  const ago = Math.round((now - timestamp) / 1000);

  if (ago < 5) {
    return "just now";
  } else if (ago < 60) {
    return `${ago} seconds ago`;
  } else if (ago < 60 * 60) {
    return `${Math.round(ago / 60)} minutes ago`;
  } else if (ago < 60 * 60 * 24) {
    return `${Math.round(ago / 60 / 24)} hours ago`;
  } else if (ago < 60 * 60 * 24 * 365) {
    return `${Math.round(ago / 60 / 24 / 365)} days ago`;
  } else {
    return `${Math.round((ago / 60 / 24 / 365) * 12)} months ago`;
  }
};

const formatLastUsed = (lastUsedAt, lastUsedIp) => (
  <span>
    {ppTime(lastUsedAt * 1000)} from{" "}
    <a href={"https://tools.keycdn.com/geo?host=" + lastUsedIp}>{lastUsedIp}</a>
  </span>
);

const APITokenRow = ({ id, name, last_used_at, last_used_ip, onDeleteToken }) => (
  <tr key={id}>
    <td>{name}</td>
    <td>{last_used_at === null ? "never used" : formatLastUsed(last_used_at, last_used_ip)}</td>
    <td>
      <Button bsStyle="danger" onClick={() => onDeleteToken(id)}>
        Delete
      </Button>
    </td>
  </tr>
);

export default Settings;
