import React, { Component } from "react";
import { Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import fetchJson from "./fetchJson.js";

import './Highlights.css';

class Highlights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlights: [],
    };
  }

  componentDidMount() {
    fetchJson(`/api/highlights?book_id=${this.props.bookId}`).then(data =>
      this.setState({
        highlights: data,
      })
    );
  }

  render() {
    return (
      this.state.highlights.length === 0 ? null : <div>
        <h2>Highlights <Badge>{this.state.highlights.length}</Badge></h2>
        <ListGroup>
          {this.state.highlights.map(h => <Highlight {...h} />)}
        </ListGroup>
      </div>
    );
  }
}

const Highlight = (props) => (
  <ListGroupItem className={'highlight-' + props.type}>
    <p className="highlight-position">{props.position_type} {props.position}</p>
    <p>{props.text}</p>
    <p>{props.note}</p>
  </ListGroupItem>
);

export { Highlights, Highlight };
