import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { BookView } from "./Book.js";
import BookList from "./BookList.js";
import Login from "./Login.js";
import Navigation from "./Navigation.js";
import Search from "./Search.js";
import Settings from "./Settings.js";

import fetchJson from "./fetchJson.js";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null };
  }

  componentDidMount() {
    fetchJson("/api/authenticated_user").then(data => this.setState({ user: data.user }));
  }

  signOut() {
    fetchJson("/api/signout").then(data => this.setState({ user: null }));
  }

  loginOrApp() {
    if (this.state.user === null) {
      return <Login onAuthenticateUser={user => this.setState({ user: user })} />;
    } else {
      return (
        <Switch>
          <Route exact path="/" component={BookList} />
          <Route exact path="/books/:id" component={BookView} />
          <Route path="/search" component={Search} />
          <Route path="/settings" component={Settings} />
        </Switch>
      );
    }
  }

  render() {
    return (
      <Router>
        <div className="App">
          <Navigation user={this.state.user} onSignOut={() => this.signOut()} />
          {this.loginOrApp()}
        </div>
      </Router>
    );
  }
}

export default App;
