import React from "react";
import { Navbar, NavItem, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Navigation = props => (
  <Navbar>
    <Navbar.Header>
      <LinkContainer to="/">
        <Navbar.Brand>Bookshelf</Navbar.Brand>
      </LinkContainer>
    </Navbar.Header>
    {props.user && (
      <Nav>
        <LinkContainer to="/search">
          <NavItem eventKey={1}>Search</NavItem>
        </LinkContainer>
        <LinkContainer to="/settings">
          <NavItem eventKey={2}>Settings</NavItem>
        </LinkContainer>
        <NavItem onClick={() => props.onSignOut()}>Sign Out</NavItem>
      </Nav>
    )}
  </Navbar>
);

export default Navigation;
