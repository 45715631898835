import React from "react";
import { FormControl, ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";

/* From https://react-bootstrap.github.io/components.html#forms */
const FieldGroup = ({ id, label, help, onChangeValue, validationState, children, ...props }) => (
  <FormGroup controlId={id} validationState={validationState}>
    <ControlLabel>{label}</ControlLabel>
    <FormControl onChange={e => onChangeValue(id, e.target.value)} {...props} />
    {help && <HelpBlock>{help}</HelpBlock>}
    {children}
  </FormGroup>
);

export default FieldGroup;
