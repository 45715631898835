import React, { Component } from "react";
import { Col, Grid, Row, FormControl, Button, ControlLabel, FormGroup } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import sortBy from "lodash.sortby";
import strftime from "strftime";
import moment from "moment";
import fetchJson from "./fetchJson.js";
import FieldGroup from "./FieldGroup.js";
import { Highlights } from "./Highlights";

class BookView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      book: null,
      isSaving: false,
    };
  }

  bookId() {
    return this.props.match.params.id;
  }

  componentDidMount() {
    fetchJson(`/api/books/${this.bookId()}`).then(data =>
      this.setState({
        book: data,
      })
    );
  }

  // TODO: Make Book child component fully controlled (store the canonical and
  // draft state in this parent component) or use an id based on the book id +
  // updated_at to reset the child component when there are updates.
  //
  // From https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
  save(data) {
    this.setState({ isSaving: true });

    const formData = new FormData();
    ["title", "subtitle", "author", "status", "finished_at", "rating", "notes"].forEach(
      property => {
        const prop = data[property];
        formData.append(property, prop === null ? "" : prop);
      }
    );

    // for(let pair of formData.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]);
    // }

    fetchJson(`/api/books/${this.state.book.id}`, {
      body: formData,
      method: "PUT",
    }).then(rsp => {
      this.setState({
        book: rsp,
        isSaving: false,
      });
      this.props.history.push("/");
    });
  }

  delete(id) {
    const shouldDelete = window.confirm("Are you sure you want to delete this book?");
    if (!shouldDelete) {
      return;
    }

    fetchJson(`/api/books/${this.state.book.id}`, {
      method: "DELETE",
    }).then(rsp => {
      this.setState({
        book: rsp,
        isSaving: false,
      });
      this.props.history.push("/");
    });
  }

  render() {
    return (
      <div>
        {this.state.book && (
          <Book
            book={this.state.book}
            isSaving={this.state.isSaving}
            onSave={data => this.save(data)}
            onDelete={id => this.delete(id)}
          />
        )}
      </div>
    );
  }
}

const FORMAT_STRING = "YYYY-MM-DD HH:mm:ss";
const FORMAT_STRING_ROUNDED = "YYYY-MM-DD HH:00:00";

class Book extends Component {
  constructor(props) {
    super(props);

    /* TODO: Use moment instead of strftime */
    this.state = Object.assign({}, this.props.book, {
      finishedAtStr: props.book.finished_at
        ? strftime("%Y-%m-%d %H:%M:%S", new Date(props.book.finished_at * 1000))
        : "",
      isValidFinishedAt: true,
    });
  }

  biggestBookImage() {
    const bySize = sortBy(this.props.book.cover_images, i => i.width);
    return bySize[bySize.length - 1];
  }

  bookImage() {
    const src = this.biggestBookImage();
    return src ? (
      <img alt="Book cover" src={this.biggestBookImage().url} style={{ width: "100%" }} />
    ) : (
        <div style={{ width: "100%", height: "350px", backgroundColor: "#ccc" }} />
      );
  }

  setBookProperty(name, value) {
    this.setState({ [name]: value });
  }

  setFinishedAt(str) {
    if (/^\s*$/.test(str)) {
      this.setState({
        finishedAtStr: "",
        isValidFinishedAt: true,
      });
    } else {
      const m = moment(str, FORMAT_STRING);
      this.setState({
        finishedAtStr: str,
        isValidFinishedAt: m.isValid(),
        finished_at: m.isValid() ? m.format(FORMAT_STRING) : this.state.finished_at,
      });
    }
  }

  saveState() {
    // TODO: Use lodash.pick?
    return {
      title: this.state.title,
      subtitle: this.state.subtitle,
      author: this.state.author,
      status: this.state.status,
      finished_at: this.state.finished_at,
      rating: this.state.rating,
      notes: this.state.notes,
    };
  }

  isValid() {
    const nonEmpty = s => /[^\s]/.test(s);
    const validStatus = { reading: 1, read: 1, unread: 1 };

    const v =
      nonEmpty(this.state.title) &&
      nonEmpty(this.state.author) &&
      this.state.status in validStatus &&
      (this.state.finishedAtStr === "" || this.state.isValidFinishedAt) &&
      (this.state.status !== "read" ||
        (this.state.isValidFinishedAt && this.state.finishedAtStr !== ""));

    return v;
  }

  bookView() {
    return (
      <Row>
        <Col xs={12} sm={4} md={3}>
          {this.bookImage()}
        </Col>
        <Col xs={12} sm={8} md={9}>
          <form>
            {/* TODO: This should be less repetitive */}
            <FieldGroup
              id="title"
              label="Title"
              value={this.state.title}
              onChangeValue={(k, v) => this.setBookProperty(k, v)}
            />
            <FieldGroup
              id="subtitle"
              label="Subtitle"
              value={this.state.subtitle}
              onChangeValue={(k, v) => this.setBookProperty(k, v)}
            />
            <FieldGroup
              id="author"
              label="Author"
              value={this.state.author}
              onChangeValue={(k, v) => this.setBookProperty(k, v)}
            />

            <FormGroup controlId="status">
              <ControlLabel>Status</ControlLabel>
              {/* TODO: Autofill current time (or last value for finished at) when switching to read  */}
              <FormControl
                componentClass="select"
                placeholder="select"
                value={this.state.status}
                onChange={e => this.setBookProperty("status", e.target.value)}
              >
                <option value="unread">Unread</option>
                <option value="reading">Reading</option>
                <option value="read">Read</option>
              </FormControl>
            </FormGroup>

            <FormGroup controlId="rating">
              <ControlLabel>Rating</ControlLabel>
              <FormControl
                componentClass="select"
                placeholder="select"
                value={this.state.rating}
                onChange={e => this.setBookProperty("rating", e.target.value)}
              >
                <option value="" />
                <option value="1">★</option>
                <option value="2">★★</option>
                <option value="3">★★★</option>
                <option value="4">★★★★</option>
                <option value="5">★★★★★</option>
              </FormControl>
            </FormGroup>

            <FieldGroup
              id="finished_at"
              label="Finished"
              autocomplete="off"
              value={this.state.finishedAtStr}
              validationState={this.state.isValidFinishedAt ? null : "error"}
              onChangeValue={(k, v) => this.setFinishedAt(v)}
              children={
                <Button onClick={() => this.setFinishedAt(moment().format(FORMAT_STRING_ROUNDED))}>
                  Now
                </Button>
              }
            />

            <FieldGroup
              id="notes"
              label="Notes"
              value={this.state.notes}
              onChange={e => this.setBookProperty("notes", e.target.value)}
              componentClass="textarea"
            />

            <Row>
              <Col md={3}>
                <LinkContainer exact to="/">
                  <Button block>Cancel</Button>
                </LinkContainer>
              </Col>
              <Col md={3}>
                <Button
                  block
                  disabled={!this.isValid() || this.props.isSaving}
                  type="submit"
                  bsStyle="primary"
                  onClick={e => {
                    e.preventDefault();
                    this.props.onSave(this.saveState());
                  }}
                >
                  Save
                </Button>
              </Col>
              <Col md={3} mdOffset={3}>
                <Button
                  block
                  type="submit"
                  bsStyle="danger"
                  onClick={e => {
                    e.preventDefault();
                    this.props.onDelete(this.props.id);
                  }}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            {this.bookView()}
            <hr />
            {this.props.book && <Highlights bookId={this.props.book.id} />}
            <hr />
            <pre>{JSON.stringify(this.props.book, null, 2)}</pre>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export { Book, BookView };
